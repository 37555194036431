import React, { Component } from 'react';

import craigmon from '../images/craigmon.png'
import fta from '../images/fta.png'
import logo from '../images/logo.png';

class PortfolioSection extends Component {
  render() {
    return (
      <div id="portfolio" className="container-fluid text-center bg-grey">
        <h2>Portfolio</h2><br />
        <h4>Our Public Software Solutions</h4>
        <div className="row text-center slideanim">
          <div className="col-sm-4">
            <div className="thumbnail">
              <a href="#beta-access">
                <img src={craigmon} alt="CraigslistMonitor" width="400" height="300" />
              </a>
              <p><strong>CraigslistMonitor</strong></p>
              <p>Get alerts for Craigslist. <br />Great for snagging deals quickly!</p>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="thumbnail">
              <a href="#beta-access">
                <img src={fta} alt="FootTrafficAnalyzer" width="400" height="300" />
              </a>
              <p><strong>FootTrafficAnalyzer</strong></p>
              <p>Find the best place to setup your mobile business. <br />Great for making extra money from food trucks or stands!</p>
            </div>
          </div>
          
          <div className="col-sm-4">
            <div className="thumbnail">
              <a href="#beta-access">
                <img src={logo} alt="More Coming Soon" width="400" height="300" />
              </a>
              <p><strong>More Coming Soon</strong></p>
              <p>We are continously powering new industries</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PortfolioSection;
