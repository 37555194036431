import React, { Component } from 'react';

class ServicesSection extends Component {
  render() {
    return (
      <div id="services" className="container-fluid text-center">
        <h2>SERVICES</h2>
        <h4>What we offer</h4>
        <br />
        <div className="row slideanim">
          <div className="col-sm-6">
            <span className="glyphicon glyphicon-off logo-small"></span>
            <h4>STRENGTH</h4>
            <p>You get the strength of over 35 years of software development experience. <br />We know how to write software that works.</p>
          </div>
          <div className="col-sm-6">
            <span className="glyphicon glyphicon-heart logo-small"></span>
            <h4>WE CARE</h4>
            <p>We have had experience in the Telecom, Financial, Medical, and Insurance industry. <br />We care because we used to work with people just like you who fought software.</p>
          </div>
        </div>
        <br /><br />
        <div className="row slideanim">
          <div className="col-sm-6">
            <span className="glyphicon glyphicon-wrench logo-small"></span>
            <h4 style={{color: '#303030'}}>SOLID ENGINEERING</h4>
            <p>Building software that works is easy. <br />Building software that works correctly for as long you desire is just the HTS specialty.</p>
          </div>
          <div className="col-sm-6">
            <span className="glyphicon glyphicon-lock logo-small"></span>
            <h4>PROFESSIONALS IN ACTION</h4>
            <p>Don't fall for the 'cheap trap'. Risking your business over a few dollars isn't worth it to us. <br />We're not 'cheap', we're just 'affordable', but we can work within any budget.</p>
          </div>
        </div>
      </div>
    )
  }
}

export default ServicesSection;
