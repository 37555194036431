import React, { Component } from 'react';

class JumboTron extends Component {
  render() {
    return (
      <div className="jumbotron text-center">
        <h1>Herrera Technology Solutions</h1> 
        <p>We build the software that makes your small business more money</p> 
      </div>
    )
  }
}

export default JumboTron;
