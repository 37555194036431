import React, { Component } from 'react';

class AboutSection extends Component {
  render() {
    return (
      <>
        <div id="about" className="container-fluid">
          <div className="row">
            <div className="col-sm-8">
              <h2>About Our Vision</h2>
              <h4>A Small Business Innovator since 2015.</h4>
              <p>Herrera Technology Solutions (HTS for short) was started with one goal, to help small businesses get the tools they need to make more money and expand. 
              We truly believe providing the best tools gives us the best chance of helping you succeed and are motivated to help you succeed beyond your wildest expectations.
              When you work with us, you don't get some IT nerds, you get real people invested in you and your company's success, who will do anything to help you succeed.</p>
              <br /><a className="btn btn-default form-control" href="mailto:contact@herreratechnologysolutions.com">Talk To Us!</a>
            </div>
            <div className="col-sm-4">
              <span className="glyphicon glyphicon-signal logo"></span>
            </div>
          </div>
        </div>

        <div className="container-fluid bg-grey">
          <div className="row">
            <div className="col-sm-4">
              <span className="glyphicon glyphicon-globe logo slideanim"></span>
            </div>
            <div className="col-sm-8">
              <h2>Our Values</h2>
              <h4><strong>MISSION:</strong> Our company's mission is to replace pain points in larger software that aren't perfectly suited for your company and we fill in the niche that keeps you from the making the most money.</h4>
              <p><strong>VISION:</strong> Our vision is a world where every small business within every industry has the tools needed to succeed. You shouldn't have to settle for software that gets in the way of your job.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default AboutSection;
