import React, { Component } from 'react';
import './App.css';
import NavBar from './containers/NavBar';
import JumboTron from './containers/JumboTron';
import About from './containers/AboutSection';
import Services from './containers/ServicesSection';
import Portfolio from './containers/PortfolioSection';
import Contact from './containers/ContactSection';

class App extends Component {
  render() {
    return (
      <>
        <NavBar />
        <JumboTron />
        <About />
        <Services />
        <Portfolio />
        <Contact />
      </>
    );
  }
}

export default App;
