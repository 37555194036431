import React, { Component } from 'react';

class ContactSection extends Component {
  render() {
    return (
      <div id="contact" className="container-fluid bg-grey">
        <h2 className="text-center">CONTACT</h2>
        <div className="row">
          <div className="text-center col-sm-12">
            <p>Contact us and we'll get back to you.</p>
            <p className="text-center col-sm-4"><span className="glyphicon glyphicon-map-marker"></span> Miami, FL</p>
            <p className="text-center col-sm-4"><span className="glyphicon glyphicon-phone"></span> <a href="tel:+13052099434">3052099434</a></p>
            <p className="text-center col-sm-4"><span className="glyphicon glyphicon-envelope"></span> <a href="mailto:contact@herreratechnologysolutions.com">contact@herreratechnologysolutions.com</a></p>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactSection;
